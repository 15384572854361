body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-header {
  display: flex;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.table-cell {
  text-align: center;
  font-weight: medium;
}

.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.centerModel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.heading {
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  outline: none;
}

.submitButton {
  padding: 10px;
  border: 1px solid #33dd27;
  background-color: #33dd27;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.hubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.hub-item {
  display: flex;
  padding: 10px;
  background-color: teal;
  border: 1px solid black;
  color: white;
  border-radius: 5px;
  width: 20%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.back {
  padding: 20px;
  padding-left: 22px;
  padding-bottom: 18px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.headings {
  font-weight: medium;
  font-size: medium;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.headings-title {
  font-weight: bold;
  margin-top: 20px;
  /* margin-left: 22px; */
  margin-bottom: 20px;
}

.heading-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 22px;
}
.nav {
  display: flex;
  align-items: center;
}

.collapsible {
  display: flex;
  /* width: 100%; */
  align-items: flex-end;
}

.Collapsible {
  display: flex;
  align-items: flex-end;
  margin-left: 22px;
}

.Collapsible__trigger {
  display: block;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  /* border: 1px solid black; */
  border-radius: 20px;
  padding: 10px;
  color: black;
  position: absolute;
  left: 300px;
  top: 10px;
  display: block;
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchButton {
  display: block;
  font-weight: 500;
  text-decoration: none;
  /* position: absolute; */
  text-decoration: none;
  border-bottom: 1px solid black;
  /* border-radius: 20px; */
  padding: 20px;
  color: black;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  /* margin-bottom: 5px;
  margin-top: 5px; */
}

.hamburger {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.sideMenu {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100px;
}

.active {
  background-color: rgba(0, 128, 128, 0.385);
}
